
import { Component, Vue } from 'vue-property-decorator'
import { IssueDetail } from '@/types/inspection'
@Component({
  name: 'IssueConfirm'
})
export default class IssueConfirm extends Vue {
  private searchInfo: { projectName: string; dateRange: Array<string>; patrolType: string} = {
    projectName: '',
    dateRange: [],
    patrolType: ''
  }

  private loading = false
  private finished = false
  private list: Array<IssueDetail> = []
  private activeIndex: Array<number> = []
  private page = 1
  private size = 10
  private currentSrc = ''
  private patrolTypeList: object[] = []

  created (): void {
    this.getPatrolTypeList()
  }

  // 获取巡查类别列表
  getPatrolTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'patrolType' }).then(res => {
      this.patrolTypeList = res.patrolType || []
    })
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<IssueDetail> }>(this.$apis.inspection.selectNeedConfirmIssueList,
      {
        projectName: this.searchInfo.projectName,
        startDate: this.searchInfo.dateRange && this.searchInfo.dateRange[0] ? this.searchInfo.dateRange[0] : '',
        endDate: this.searchInfo.dateRange && this.searchInfo.dateRange[1] ? this.searchInfo.dateRange[1] : '',
        patrolType: this.searchInfo.patrolType,
        page: this.page,
        size: this.size
      }).then(res => {
      this.page++
      this.list = res && res.list.length > 0 ? this.list.concat(res.list) : this.list
      this.finished = this.list.length === res.total
    }).catch(() => {
      this.loading = false
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.list = []
    this.activeIndex = []
    this.getList()
  }

  carouselChange (index: number, val: number) {
    this.$set(this.activeIndex, index, val + 1)
  }

  enlargeImg (src: string) {
    this.currentSrc = src
  }

  // 问题确认
  issueConfirm (issueId: string, issueStatus: string) {
    this.$axios.post(this.$apis.inspection.updateConfirmStatus, {
      issueId: issueId,
      issueStatus: issueStatus
    }).then(() => {
      this.$message.success('确认成功')
      this.onSearch()
    })
  }
}
